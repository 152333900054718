import Layout from "../../components/layout/layout";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import React from "react";
import pageHeroDataJSON from "../../../content/sourcingPageHeroData.json";
import ServicesHero from "../../components/servicesHero/servicesHero";
import {graphql, useStaticQuery} from "gatsby";
import ServicesDetails from "../../components/servicesDetails/servicesDetails";
import HowWeDoItWide from "../../components/howWeDoIt/secondVariant/howWeDoItWide";
import FaqSection from "../../components/FaqSection/faqSection";
import Cta from "../../components/cta/cta";
import OurWork from "../../components/ourWork/ourWork";
import AdvancedCta from "../../components/cta/advancedCta";
import ctaData from "../../../content/embeddedAdvancedCtaData.json"
import MoreAboutUs from "../../components/whoWeAre/moreAboutUsExecutiveSearch";
import HowWeDoIt from "../../components/howWeDoIt/firstVariant/howWeDoIt";
import detailsJSONData from "../../../content/sourcingDetailsData.json"
import {Helmet} from "react-helmet";


export default function Sourcing() {

    const data = useStaticQuery(graphql`{
    image1: file(relativePath: {eq: "embedded/pageHero.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 70, layout: CONSTRAINED)
      }
    }
    }`);

    return <>
        <Helmet>
            <meta charSet="utf-8" />
            <html lang="en" />
            <title>Sourcing Training - Recoded Recruitment</title>
        </Helmet>
        <Layout
            header={<Header transparent={false}/>}
            footer={<Footer/>}
        >
            <ServicesHero sectionTitle={pageHeroDataJSON.sectionTitle} header={pageHeroDataJSON.header}
                          text={pageHeroDataJSON.text} buttonText={pageHeroDataJSON.buttonText}
                          image={data.image1.childImageSharp.gatsbyImageData} target={pageHeroDataJSON.target}/>
            <ServicesDetails JSONData={detailsJSONData} hideSecondSection={true} />
            {/* <HowWeDoIt/> */}
            <HowWeDoItWide />
            {/* <FaqSection/> */}
            <Cta
                maxWidth={"1114px"}
                title={"Book a call with us to discuss your hiring challenges today."}
                buttonText="Book a call"
                buttonTarget="/bookACall"
            />
            <OurWork/>
            <AdvancedCta header={ctaData.header} text={ctaData.text} firstButtonText={ctaData.firstButtonText}
                         secondButtonText={ctaData.secondButtonText} firstButtonTarget={ctaData.firstButtonTarget}
                         secondButtonTarget={ctaData.secondButtonTarget} />
            {/* <MoreAboutUs/> */}
        </Layout>
    </>
}
